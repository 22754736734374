










































































































































































import { Component, Vue } from 'vue-property-decorator';
import {
  /*getItemData,*/
  getItems,
  /*deleteItem,*/
  updateDiscount,
  updateItem,
  getRecommendTypeList
} from '@/serve/medicalAppointment/itemsManage';
import { getDisease } from '@/serve/evaluatingBefore/evaluatingQuestion';
import { getTenantData } from '@/serve/medicalAppointment/institutionManage';
@Component({
  name: 'DiscountManagement',
})
export default class DiscountManagement extends Vue {
  public tenantName = '';
  public form = {
    checkItemName: '',
    checkItemType: '',
    hospital: '',
    tenantId:""
  };
  public page = {
    current: 1,
    size: 10,
  };
  public formData = {
    name: '',
    discount: '',
    discountPrice: '',
    isRecommend:'',
    checkItemPrice:'',
    selectMedical:'',
    selectMedicalId:'',
    id: Number,
  };
  public itemData = [];
  public total = 0;
  public diseaseData = [];
  public itemDialog = false;
  public labelPosition = 'left';
  public recommendTypeList = []
  public isAdmin = sessionStorage.getItem('isAdmin');
  public restaurants = [];
  public created() {
    this.getItemData();
    this.getRecommendTypeListApi()
  }
  public mounted() {
    this.getTenantData();
  }
  public enums: any = {
    recommendType:{
    '1':'热门推荐',
    '2':'化学中毒',
    '3':'噪声性聋',
    '4':'尘肺',
    '5':'未知',
    '6':'测试',
  }
}
public enumsNew: any = {
    recommendType:{
    '1':'热门推荐',
    '2':'化学中毒',
    '3':'噪声性聋',
    '4':'尘肺',
    '5':'未知',
    '6':'测试',
  }
}
  /**获取全部租户 */
  public async getTenantData(): Promise<void> {
    const res = await getTenantData({ size: 100000, current: 1 });
    this.restaurants = res.data.records;
  }
  public handleSelect(e: any) {
    if (Object.keys(e).length > 0) this.form.tenantId = e.tId;
    else this.form.tenantId = '';
  }
  public inputChange (value: any){
    this.formData.discountPrice = String((Number(this.formData.checkItemPrice) * value).toFixed(2)) 
  }
  
  public async querySearch(queryString: any, cb: any) {
    const res = await getTenantData({
      size: 100,
      current: 1,
      name: queryString,
    });
    const restaurants = res.data.records;
    const newArr: any = [];
    restaurants.forEach((x: any) => {
      if (x.name.includes(queryString)) {
        newArr.push({ value: x.name, tId: x.id });
      }
    });
    // 调用 callback 返回建议列表的数据
    cb(newArr);
  }
  //获取项目表数据
  public async getItemData(): Promise<void> {
    const dataItem = { ...this.page, ...this.form };
    const res = await getItems(this.$changNull(dataItem));
    this.itemData = res.data.records;
    const data = {
      diseaseType: '疾病',
    };
    //获取疾病系统的数据
    const Disease = await getDisease(data);
    this.diseaseData = Disease.data.records;
    this.itemData.forEach((ele: any, index) => {
      this.diseaseData.forEach((val: any) => {
        if (ele.diseaseId === val.id) {
          this.$set(this.itemData[index], 'diseaseName', val.diseaseName);
        }
      });
    });
    this.total = res.data.total;
  }
  // 获取推荐分类
  public async getRecommendTypeListApi(){
    
    const res = await getRecommendTypeList();
    this.recommendTypeList = res.data
  }
  // 改变体检报告的值发生的方法
  public changeContent(e: any) {
  //  this.formData.selectMedical = e.recommendType
   this.formData.selectMedicalId = e.id

  }


  public async sirchUpdata(e: any){
    const {id,isRecommend} = e
    const data: any = {
      id,
      isRecommend
    }
    const res = await updateItem(data);
    if (res.code === 200) {
      this.$message({
          message: '修改成功！',
          type: 'success'
        });
    }else{
      this.$message.error(res.msg);
    }
    
  }
  //点击每页显示的条数触发获取数据
  public handleSizeChange(val: any): void {
    this.page.size = val;
    this.getItemData();
  }
  //点击第N页触发获取数据
  public handleCurrentChange(val: any): void {
    this.page.current = val;
    this.getItemData();
  }
  // 搜索
  public async search() {
    const value = { ...this.page, ...this.form };
    const res = await getItems(this.$changNull(value));
    this.itemData = res.data.records;
    this.total = res.data.total;
  }



  //编辑
  public editRow(list: any): void {
    this.itemDialog = true;
    this.formData.name = list[0].checkItemName;
    this.formData.discount = list[0].discount;
    this.formData.discountPrice = list[0].discountedPrice;
    this.formData.isRecommend = list[0].isRecommend;
    this.formData.checkItemPrice = list[0].checkItemPrice;
    this.formData.selectMedicalId = list[0].categoryId;
    this.formData.id = list[0].id;
  }
  //提交按钮
  public async handlerSubmit() {
    const { id, discount,selectMedicalId } = this.formData;
    const data: any = {
      discount,
      id: id,
      categoryId:selectMedicalId
    };
    const res = await updateDiscount(data);
    this.itemDialog = false;
    if (res.success) {
      this.$message({
        message: '修改成功',
        type: 'success',
      });
      this.getItemData();
    } else {
      this.$message.error(res.msg);
    }
  }
  // 重置功能
  public resetForm() {
    this.form = {
      checkItemName: '',
      checkItemType: '',
      hospital: '',
      tenantId:""

    };
    this.page = {
      current: 1,
      size: 10,
    };
    this.getItemData();
  }
  /** 清空机构名称事件 */
  public clearName() {
    this.form.tenantId = '';
  }
}
